<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <ResponseAlert ref="response" />
        <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div class="col-md-4 col-12 px-0 order-1 order-md-0">
            <div class="input-group">
              <input
                v-model="config.search"
                type="text"
                placeholder="Cari nama produk atau kata kunci"
                class="form-control form-control-sm"
                @keyup.enter="config.page = 1; get()"
              >
              <div class="input-group-append">
                <div
                  class="input-group-text"
                  @click="config.page = 1; get()"
                >
                  <feather-icon icon="SearchIcon" />
                </div>
              </div>
            </div>
          </div>
          <div class="order-0 order-md-1 mb-1 mb-md-0 full-width-responsive">
            <b-button
              variant="secondary"
              class="btn-min-width rounded mr-1 full-width-responsive mb-1 mb-md-0"
              @click="exportExcel"
            >
              Export Data
            </b-button>
            <b-button
              variant="primary"
              class="btn-min-width rounded mr-1 full-width-responsive mb-1 mb-md-0"
              @click="syncData"
            >
              <div
                v-if="isSyncing"
                class="d-flex align-items-center"
              >
                <BSpinner
                  small
                  style="margin-right: 5px;"
                /> Syncing...
              </div>
              <span v-else>Sync Data</span>
            </b-button>
          </div>
        </div>
        <b-col sm="12">
          <div class="table-responsive">
            <table class="table b-table">
              <thead>
                <tr>
                  <th
                    v-for="(row, key) in config.headers"
                    :key="key"
                    :width="row.width"
                    :class="row.class"
                  >
                    <a
                      v-if="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                      @click="sort(row.value)"
                    >
                      {{ row.title }}
                      <i
                        :id="row.value"
                        class="fa fa-sort"
                      />
                    </a>
                    <a
                      v-else
                      :id="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                    >
                      {{ row.title }}
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="config.total_data">
                  <tr
                    v-for="(row, key) in config.rows"
                    :key="key"
                  >
                    <td>{{ row.name }}</td>
                    <td>{{ row.no }}</td>
                    <td>{{ row.availableToSell }}</td>
                    <td>{{ row.satuan }}</td>
                    <td>{{ row.unitPriceFormat }}</td>
                    <td
                      class="d-flex"
                    >
                      <router-link
                        :to="`/${config.uri}/${row.id}`"
                        title="Detail"
                        class="btn-action"
                      >
                        <feather-icon icon="EyeIcon" />
                      </router-link>
                      <router-link
                        v-if="hasPermission('edit/produk')"
                        :to="`/${config.uri}/edit/${row.id}`"
                        title="Edit"
                        class="btn-action px-1"
                      >
                        <feather-icon icon="EditIcon" />
                      </router-link>
                    </td>
                  </tr>
                </template>
                <tr v-if="!config.total_data">
                  <td
                    :colspan="config.headers.length"
                    align="center"
                  >
                    No data available.
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="config.total_data">
                <tr>
                  <td
                    :colspan="config.headers.length"
                    class="p-0"
                  >
                    <div class="d-flex justify-content-between mt-2">
                      <b-form-select
                        v-model="config.per_page"
                        :options="perPageOptions"
                        class="w-auto"
                      />
                      <b-pagination
                        v-model="config.page"
                        :total-rows="config.total_data"
                        :per-page="config.per_page"
                        @change="gotoPage"
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal Advance Search -->
    <!-- <b-modal
      id="modal-advance-search"
      ref="modal-advance-search"
      centered
      title="Filter"
      hide-footer
    >
      <form
        @submit.prevent="advanceSearch"
      >
        <div class="animated fadeIn">
          <b-row
            class="my-1"
            align-v="center"
          >
            <b-col sm="3">
              <label
                class="h5"
                for="tag"
              >Tag</label>
            </b-col>
            <b-col sm="9">
              <v-select
                id="tag"
                v-model="search.since"
                :clearable="false"
                label="name"
                placeholder="- Cari Tag -"
                :options="masters.regencies"
                :reduce="option => option"
                @search="debounceSearch"
                @input="setTagInfo"
              >
                <template #no-options="{ search, searching, loading }">
                  Tidak ditemukan hasil pencarian
                </template>
              </v-select>
            </b-col>
          </b-row>
          <b-row
            class="my-1"
            align-v="center"
          >
            <b-col sm="3">
              <label
                class="h5"
                for="owner"
              >Owner</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                id="owner"
                v-model="search.accountId"
                placeholder="Pilih Owner"
                :options="masters.owners"
                value-field="profile.accountId"
                class="w-100"
              />
            </b-col>
          </b-row>
          <b-row
            class="my-1"
            align-v="center"
          >
            <b-col sm="3">
              <label
                class="h5"
                for="level-kfs"
              >Level KFS</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                id="level-kfs"
                v-model="search.level"
                :options="masters.levels"
                value-field="id"
                text-field="name"
                class="w-100"
              />
            </b-col>
          </b-row>
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end mt-2">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded"
            >
              Export Data
            </b-button>
            <b-button
              type="button"
              variant="outline-primary"
              class="btn-min-width rounded mx-0 mx-md-1 my-1 my-md-0"
              @click="advanceSearch('reset')"
            >
              Reset
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded"
              @click="advanceSearch"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </form>
    </b-modal> -->
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BPagination, BFormSelect, BButton, BSpinner,
} from 'bootstrap-vue'
import api from '@/utils/api'
import { exportExcel } from '@/utils/helpers'

export default {
  name: 'ListProductInventory',
  metaInfo: {
    title: 'List Produk',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BSpinner,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.product,
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Nama Barang',
          value: 'name',
          align: 'text-left',
          width: '25%',
        }, {
          title: 'Kode Barang',
          value: 'address',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Stok',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Satuan',
          value: 'owner',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Harga Jual',
          value: 'uniqueId',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Aksi',
          value: '',
          class: 'text-left',
          width: '10%',
        }],
      },
      masters: {
        regencies: [],
        owners: [
          {
            text: '- Pilih Owner -',
            profile: {
              accountId: null,
            },
          },
        ],
        levels: [
          {
            name: '- Pilih Level KFS -',
            id: null,
          },
        ],
      },
      search: {
        accountId: null,
        level: null,
        since: null,
      },
      isSyncing: false,
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
    // _.getListOption()
  },
  methods: {
    get(syncMessage) {
      this.$table.get(this.config).then(res => {
        const { isSync } = res.data.data
        if (isSync) {
          this.$refs.response.setResponse({
            message: 'Still synchronizing, please refresh the page.',
          }, 'warning')
        } else if (syncMessage) {
          this.$refs.response.setResponse(syncMessage, 'success')
          this.isSyncing = false
        }
      }).catch(() => {
        this.isSyncing = false
      })
    },
    syncData() {
      this.isSyncing = true

      this.$axios.get(`${this.config.api}/accu-sync`)
        .then(res => {
          this.get(res.data)
        })
        .catch(err => {
          this.isSyncing = false
          this.$refs.response.setResponse(err.response.data, 'danger')
        })
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.kfs}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    getListOption() {
      const getOwners = this.$axios.get(`${api.list_owner}`)
      const getLevelKFS = this.$axios.get(`${api.level}`)

      Promise.all([getOwners, getLevelKFS]).then(res => {
        const [owners, levels] = res
        owners.data.data.forEach(data => {
          data.text = data.profile.fullName
          this.masters.owners.push(data)
        })

        levels.data.data.rows.forEach(data => {
          this.masters.levels.push(data)
        })
      })
    },
    exportExcel() {
      exportExcel('List Inventory Product', `${api.product}/export`)
    },
    setTagInfo(data) {
      this.search.since = data.name
    },
    debounceSearch(value) {
      if (value !== '') {
        this.search.since = value
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchTag()
          this.masters.regencies = [...response]
        }, 500)
      }
    },
    async handleSearchTag() {
      const res = await this.$axios.get(`${api.list_regency}?search=${this.search.since}`)
      return res.data.data.rows
    },
    advanceSearch(value) {
      if (value === 'reset') {
        this.config.additional_params.accountId = this.search.accountId = null
        this.config.additional_params.level = this.search.level = null
        this.config.additional_params.since = this.search.since = null
      } else {
        if (this.search.accountId) this.config.additional_params.accountId = this.search.accountId
        if (this.search.level) this.config.additional_params.level = this.search.level
        if (this.search.since) this.config.additional_params.since = this.search.since
      }
      this.config.page = 1
      this.get()
      this.$bvModal.hide('modal-advance-search')
    },
  },
}
</script>
